@import '~app/mixins';

.team {
  display: flex;
  align-items: flex-start;

  .logoField {
    position: relative;

    button {
      padding-left: 15px;
      padding-right: 15px;
    }

    .logo {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .buttons {
    margin-top: 25px;
    display: flex;
  }

  .downgrade div {
    text-decoration: underline;
  }
}
